import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue';
import Homepage from '../views/pages/Home/Homepage.vue';
import Aboutus from '../views/pages/About/Aboutus.vue';
import Privacy from '../views/pages/Privacy/Privacy.vue';

import env from '@/enviroment';
import i18n from '@/i18n';
import lStorage from '../store/localStorage';
import store from '../store';
import {rest} from '@/utils/utils';

Vue.use(VueRouter);

const routes = [
    {
        path:"/:locale(ro|en|ru)?",
        component: {
            beforeRouteEnter: setLocale,
            render(h){ 
                return h('router-view'); 
            }
        },
        children:[
            {
                path:"",
                name:"Home",
                component:Home,
                children:[
                    {
                        path: "",
                        name: "homepage",
                        meta: {
                            text: "Principala",
                            translate: "lesson.LBL_PAGE_TITLE"
                        },
                        component: Homepage,
                    },
                    {
                        path: "about",
                        name: "about",
                        meta: {
                            text: "Principala",
                            translate: "lesson.LBL_PAGE_TITLE"
                        },
                        component: Aboutus,
                    },
                    {
                        path: "politica-de-confidentialitate",
                        name: "Privacy",
                        meta: {
                            text: "Principala",
                            translate: "lesson.LBL_PAGE_TITLE"
                        },
                        component: Privacy,
                    },
                ]
            },
        ]
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


function setLocale(to,from,next){
    let { locale } = to.params;
    if(!locale){
        locale = env.default_locale||"ro";
    }
    i18n.locale = locale;
    next();
}

export default router