const strings = {
    "LOGO_TEXT":"Academia <br/> Națională de Șah",
    "NAV_HOME":"Главная",
    "NAV_STUDIOS":"Студии",
    "NAV_PRACTISE":"Практика",
    "NAV_CORDINATES":"Координаты",
    "NAV_SCHEDULE":"Orar",
    "NAV_STORE":"Магазин",
    "NAV_TOURNAMENT":"Турниры",
    "NAV_LEADS":"Команды",
    "BUTTON_TOP_UP_FUNDS":"Пополнить",
    "BUTTON_DONATE":"Пожертвовать",
    "TECHNICAL_SUPPORT":"Тех. поддержка",
    "LEAVE_FEEDBACK":"Оставить отзыв",
    "USER_LEVEL":"Уровень",
    "META_HOME":"Главная",
    "BTN_CANCEL":"Отменить",
    "BTN_CONTINUE":"Продолжить",
    "BTN_EDIT":"Редактировать",
    "BTN_DELETE":"Удалить",
    "BTN_CONFIRM":"Подтвердить",
    "BTN_SAVE":"Сохранить",
    "MONTH_1":"январь",
    "MONTH_2":"февраль",
    "MONTH_3":"март",
    "MONTH_4":"апрель",
    "MONTH_5":"май",
    "MONTH_6":"июнь",
    "MONTH_7":"июль",
    "MONTH_8":"август",
    "MONTH_9":"сентябрь",
    "MONTH_10":"октябрь",
    "MONTH_11":"ноябрь",
    "MONTH_12":"декабрь",
    "WK_SUN":"воскресенье",
    "WK_MON":"понедельник",
    "WK_TUE":"вторник",
    "WK_WED":"среда",
    "WK_THU":"четверг",
    "WK_FRI":"пятница",
    "WK_SAT":"суббота",
    "LBL_DAYS":"дней|день|дня|дней",
    "LBL_HOURS":"часов|час|часа|часов",
    "LBL_MINS":"минут|минута|минут|минут",
    "LBL_SECONDS":"секунд|секунда|секунды|секунд",
    "BTN_PROFILE":"Профиль",
    "BTN_CHANGE":"Изменить"
};
export default strings