<template>
    <div class="simple-page">
        <div class="container">
        <h1>Politica de confidențialitate</h1>
        <p>POLITICA DE SECURITATE A PRELUCRĂRII DATELOR CU CARACTER PERSONAL DISPOZIȚII GENERALE 

            <br/><br/>Prezenta „Politica de securitate a prelucrării datelor cu caracter personal” este un document juridic, în care se explică în ce modalitate compania SC ”Șah pentru toți” SRL MD-2084, str. Dosoftei, nr. 1a, or. Cricova, mun.Chișinău, Republica Moldova, contul curent 22512646591/MDL, COD IBAN: MD91AG000000022512646591 colectează, păstrează, prelucrează și transmite datele pe care le primim în momentul cînd Vă oferim acces la platforma noastră. Politica a fost elaborată în conformitate cu prevederile legislaţiei naţionale în vigoare, inclusiv a Legii nr. 133 din 08.07.2011 privind protecţia datelor cu caracter personal şi reglementează modul în care sunt colectate și utilizate aceste informații, precum și condițiile de utilizare a informației în cadrul companiei SC Șah pentru toți. 
            
            <br/> <br/>COLECTAREA DATELOR CU CARACTER PERSONAL Scopul acestei politici de securitate este acela de a asigura nivelul corespunzător al protecţiei datelor cu caracter personal ale persoanelor vizate, prin aplicarea corespunzatoare a legislaţiei naţionale cu referire la protecţia datelor şi confidentialitatea comunicării. 
            
            <br/> <br/>PRELUCRAREA DATELOR PERSONALE Prelucrarea datelor cu caracter personal se realizează prin mijloace mixte (manuale şi automate), cu respectarea cerinţelor legale şi în condiţiile care să asigure securitatea, confidenţialitatea şi respectarea drepturilor persoanelor vizate. Prelucrarea datelor cu caracter personal va fi realizată de persoanele împuternicite din cadrul companiei sau de către alte persoane împuternicite în condițiile legii. În conformitate cu prevederile Legii nr. 133 din 08.07.2011, subiectul datelor cu caracter personal are următoarele drepturi:  
            
            <br/> <br/>Dreptul de acces la datele cu caracter personal ∙ 
            <br/>Dreptul de intervenţie asupra datelor cu caracter personal ∙ 
            <br/>Dreptul de opoziţie al subiectului datelor cu caracter personal 
            <br/>Prin semnatea unui contract sau, după caz, prin utilizarea serviciilor companiei, clientul acceptă ca datele sale cu caracter personal să fie prelucrate în următoarele scopuri: — constituirea de baze de date şi utilizarea acestora în legatură cu furnizarea serviciilor și produselor (inclusiv a serviciilor aferente) ce fac obiectul contractului semnat cu compania SC
            
            <br/> <br/>ȘPT; — asigurarea executării obligaţiilor clientului stabilite prin contract, prin transmiterea acestor date către companiile de colectare a datoriilor în scopul recuperării creanţelor de la clienți. Pentru realizarea dreptului său de creanţă, stabilit în contract compania SC ȘPT va transmite informaţia companiilor de colectare a datoriilor, informaţia despre identitatea clienților şi suma creanţei. Prin semnarea contractului sau utilizarea serviciilor, clientul îşi exprimă acordul pentru procesarea datelor sale cu caracter personal. Refuzul clientului pentru prelucrarea acestor date se echivalează cu imposibilitatea prestării serviciilor. 
            
            <br/> <br/>SECURITATEA INFORMAŢIILOR. Măsurile de securitate a datelor cu caracter personal sunt stabilite astfel încât să asigure un nivel adecvat de securitate a datelor cu caracter personal procesate. Compania ȘPT îndeplinește cerințele de securitate a datelor cu caracter personal şi garantează protejarea informației şi sistemelor informatice de accesul neautorizat, folosirea, dezvăluirea, întreruperea, modificarea ori distrugerea accidentală sau ilegală a datelor cu caracter personal. 
            
            <br/> <br/>PRINCIPIILE PRELUCRĂRII DATELOR CU CARACTER PERSONAL: 
            
            <br/> <br/>Datele cu caracter personal sunt prelucrate cu buna-credinţă şi în conformitate cu dispoziţiile legale în vigoare. 
            <br/>Datele cu caracter personal sunt colectate numai în scopuri determinate, explicite şi legitime, iar prelucrarea ulterioară nu va fi incompatibilă cu aceste scopuri. 
            <br/>Datele cu caracter personal care fac obiectul prelucrării sunt adecvate, pertinente şi neexcesive prin raportare la scopul în care sunt colectate 
            <br/>Datele cu caracter personal care fac obiectul prelucrării sunt exacte şi, dacă este cazul, actualizate. 
            <br/>Datele cu caracter personal nu se stochează pentru o perioadă mai lungă decît este necesar pentru realizarea scopurilor în care au fost colectate. 
            <br/>Datele cu caracter personal se prelucrează în conformitate cu drepturile persoanei vizate prevăzute de Legea privind protecţia datelor cu caracter personal Nr. 133 din 8 iulie 2011 7. Se vor lua măsurile necesare pentru păstrarea confidenţialităţii datelor cu caracter personal în conformitate cu prevederile legislaţiei în vigoare şi ale contractului semnat între companie și client. 
            <br/>Se vor lua măsurile necesare pentru că datele inexacte sau incomplete din punct de vedere al scopului în care sunt colectate şi pentru care vor fi prelucrate, să fie şterse sau rectificate. 
            <br/>Se vor lua masurile tehnice şi organizatorice (procedurale) adecvate pentru protejarea datelor cu caracter personal împotriva distrugerii accidentale sau ilegale, pierderii, modificării, dezvăluirii sau accesului neautorizat. 
                
        </p>
        <span class="contact">Mariana Gîrneț, Administrator<a href="tel:/+37378888240">+373 78 888 240</a></span>
        </div>
    </div>
</template>

<style lang="scss">
    
</style>

<script>
    import i18n from '@/i18n';
    import RegistrationForm from '@/views/components/RegistrationForm/RegistrationForm.vue';

    export default {
        name: 'Privacy',
        components: {
            RegistrationForm
        },
        data: function () {
            return {}
        },
        methods: {},
        computed:{
            url_prefix(){
                return (i18n.locale=="ro"?"":"/"+i18n.locale);
            }
        },
        mounted(){
            
        },
    }
</script>