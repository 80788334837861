<template>
    <div>
        <Popup class="gray-box" :show="display_login_form" @closeEvent="display_login_form=false">
            <h2>Bine ai venit!</h2>
            <div class="form">
                <div class="input-wrapper">
                    <div class="icon-holder">
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="user">
                            <path id="Path" d="M17 19V17C17 14.7909 15.2091 13 13 13H5C2.79086 13 1 14.7909 1 17V19" stroke="#0B1022" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path id="Oval" fill-rule="evenodd" clip-rule="evenodd" d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z" stroke="#0B1022" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                        </svg>
                    </div>
                    <input placeholder="Email" v-model="email"/>
                </div>
                <div class="input-wrapper">
                    <div class="icon-holder">
                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="lock">
                            <rect id="Rectangle" x="1" y="10" width="18" height="11" rx="2" stroke="#0B1022" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path id="Path" d="M5 10V6C5 3.23858 7.23858 1 10 1C12.7614 1 15 3.23858 15 6V10" stroke="#0B1022" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                        </svg>
                    </div>
                    <input type="password" placeholder="Password" v-model="password">
                </div>
                <p class="form-error" v-html="error"></p>
                <div class="button-wrapper">
                    <a href="#" @click="display_recover_password=true;display_login_form=false;">Ați uitat parola?</a>
                </div>
                <button class="button" @click="login('form')">log in </button>
                <a href="#" @click="show_reg_modal_window=true;display_recover_password=false;display_login_form=false;" style="width: 100%; text-decoration: none;">
                    <button class="button secondary" style="margin-top: 4px; width: 100%;">Înregistrează-te </button>
                </a>
                <div class="social-login">
                    <div class="google-login-button noselect" @click="login('google')">
                        <img src="@/assets/img/google.svg" alt="fb">
                        <span>Autentificare prin Google</span>
                    </div>
                    <div class="fb-login-button noselect" @click="login('fb')">
                        <img style="padding: 4px;" src="@/assets/img/fb.svg">
                        <span>Autentificare prin Facebook</span>
                    </div>
                </div>
            </div>
        </Popup>
        <Popup class="gray-box" :show="display_recover_password" @closeEvent="display_recover_password=false">
            <div v-if="!message_sent">
                <h2>Bine ai venit!</h2>
                <div class="form">
                    <div class="input-wrapper">
                        <div class="icon-holder">
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="user">
                                <path id="Path" d="M17 19V17C17 14.7909 15.2091 13 13 13H5C2.79086 13 1 14.7909 1 17V19" stroke="#0B1022" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path id="Oval" fill-rule="evenodd" clip-rule="evenodd" d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z" stroke="#0B1022" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                            </svg>
                        </div>
                        <input placeholder="Indicați adresa de email" v-model="recover_email"/>
                    </div>
                    <p>Introduceți adresa de e-mail și vă vom trimite noua parolă</p>
                    <button v-if="!recover_password_loading" class="button"  @click="recover_password">
                        <span>Expediați</span>
                    </button>
                    <div class="button form-button" v-if="recover_password_loading" type="button" value="">
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
            </div>
            <div v-else>
                <h2>Parola trimisă</h2>
                <div class="form">
                    <span>Verificați-vă e-mailul dacă sunteți membru al nostru - veți primi un e-mail cu o nouă parolă</span>
                    <button class="button"  @click="display_recover_password=false;message_sent=false;display_login_form=true;">
                        <span>Log in</span>
                    </button>
                </div>
            </div>
        </Popup>
        <Popup :show="display_error" @closeEvent="display_error=false">
            <h2 v-html="error_heading"></h2>
            <div class="form">
                <p class="text" v-html="error_text"></p>
            </div>
            <div class="popup-actions">
                <div class="button" v-for="(button,index) in error_buttons" @click="callButtonAction(index)">
                    <span v-html="button.label"></span>
                </div>
            </div>
        </Popup>
        <Popup :show="show_reg_modal_window" @closeEvent="show_reg_modal_window=false">
            <h2>Înregistrează-te</h2>
            <RegistrationForm />
        </Popup>
    </div>
</template>

<style lang="scss">
    
</style>

<script>
    import i18n from '@/i18n';
    import {rest} from "@/utils/utils";
    import Popup from '@/views/components/Popup/Popup.vue';
    import RegistrationForm from '@/views/components/RegistrationForm/RegistrationForm.vue';
    import env from '@/enviroment';

    export default {
        name: 'LoginBox',
        components: {
            Popup,
            RegistrationForm
        },
        data: function () {
            return {
                email:"",
                recover_email:"",
                password:"",
                error:"",
                recover_password_loading:false,
                display_login_form:false,
                display_recover_password:false,
                message_sent:false,
                display_error:false,
                error_buttons:[],
                error_heading:"",
                error_text:"",
                show_reg_modal_window:false
            }
        },
        methods:{
            login(method){
                switch(method){
                    case "form":
                        var body = {
                            'user_auth': {
                                "email":this.email,
                                "password":this.password
                            },
                            "language":"ro",
                            "elev_id":null,
                            "return":typeof this.$route.query.return != "undefined" ? encodeURIComponent(this.$route.query.return) : null
                        };
                        rest.call("auth",body,"POST",false).then((response)=>{
                            if(response.description){
                                this.error = response.description;
                            }else if(response.code){
                                switch(response.code){
                                    case "412":
                                        this.error_buttons = [];
                                        this.display_error = true;
                                        this.error_heading = "Autentificare esuata";
                                        this.error_text = "Așa utilizator nu există. Rugăm să Vă înregistrați";
                                        this.error_buttons.push({
                                            label:"OK",
                                            action:()=>{
                                                this.display_error = false;
                                                return true;
                                            },
                                        });
                                    break;
                                }
                            }else if(response.redirect){
                                window.location.href = response.redirect;
                            }
                        });
                    break;
                    case "fb":
                        var return_url = typeof this.$route.query.return != "undefined" ? encodeURIComponent(this.$route.query.return) : null;
                        window.location = env.backend+'/index.php?entryPoint=client_auth&provider=facebook&action=login&deal_id=&language=ro&return='+return_url;
                    break;
                    case "google":
                        var return_url = typeof this.$route.query.return != "undefined" ? encodeURIComponent(this.$route.query.return) : null;
                        window.location = env.backend+'/index.php?entryPoint=client_auth&provider=google&action=login&deal_id=&language=ro&return='+return_url;
                    break;
                }
            },
            recover_password(){
                this.recover_password_loading = true;
                var body = {
                    "email":this.recover_email,
                };
                rest.call("recover_password",body,"POST",false).then((response)=>{
                    this.recover_password_loading = false;
                    this.message_sent = true;
                });
            }
        },
        computed:{
            
        },
    }
</script>