<template>
    <header class="header">
        <div class="container">
            <div class="header-primary flex-between-center">
                <a :href="url_prefix+'/'">
                    <img class="head" src="@/assets/img/Logo.png" alt="Logo" >
                </a>
                <nav>
                    <ul class="navigation">
                        <li>
                            <a :href="url_prefix+'/about'">Despre noi</a>
                        </li>
                    </ul>
                </nav>
                <a href="#" @click="$refs.login_box.display_login_form=true" class="button simply">Log in</a>
            </div>
            <div class="header-secondary">
                <nav>
                    <ul class="navigation">
                        <li>
                            <a :href="url_prefix+'/about'">Despre noi</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <LoginBox ref="login_box"/>
    </header>
</template>

<style lang="scss">
    header{
        >.container{
            .header-primary{
                width:100%;
            }
            .header-secondary{
                display: none;
                width: 100%;
            }
        }
    }
    @media(max-width:900px){
        header{
            >.container{
                .header-primary{
                    nav{
                        display:none;
                    }
                }
                .header-secondary{
                    display: inline-block;
                }
            }
        }
    }
</style>

<script>
    import i18n from '@/i18n';
    import LoginBox from '@/views/components/LoginBox/LoginBox.vue';
    import {ifAuthentificated} from "@/utils/utils";

    export default {
        name: 'Header',
        components: {
            LoginBox
        },
        data: function () {
            return {}
        },
        mounted(){
            ifAuthentificated().then((data)=>{
                console.log(data);
            });
        },
        methods: {
            
        },
        computed:{
            url_prefix(){
                return (i18n.locale=="ro"?"":"/"+i18n.locale);
            }
        },
    }
</script>