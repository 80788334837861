<template>
    <div class="application-container">
        <Header />
        <transition name="component-fade-route" mode="out-in">
            <router-view :key="$route.fullPath"/>
        </transition>
        <Footer />
    </div>
</template>

<style lang="scss">
@import "../assets/style/style.scss";
</style>

<script>
    import i18n from '@/i18n';
    import Header from '@/views/containers/Header/Header.vue';
    import Footer from '@/views/containers/Footer/Footer.vue';

    export default {
        name: 'Home',
        components: {
            Header,
            Footer
        },
        data: function () {
            return {}
        },
        methods: {},
        computed:{
            url_prefix(){
                return (i18n.locale=="ro"?"":"/"+i18n.locale);
            }
        },
        mounted(){
            window.VerboxSetup = {
                language: "ru",
            };
        },
    }
</script>