const Cookie = function () {
    'use strict';
    function eqNull(value) {
        return (((typeof value) === 'undefined') || (value === null) || (value === ''));
    }
    function StorageService() {
        this.VERSION_STORAGE = '0.0.0';
    }

    /**
     * Устанавливает значение куки
     * @param {} key 
     * @param {*} data 
     */
    StorageService.prototype.set = function(key,data,options){
        var defaults = {
            path: '/',
        };
        options = Object.assign(defaults,options);

        if(options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }
        let updatedCookie = encodeURIComponent(key) + "=" + encodeURIComponent(data);
        
        for (let optionKey in options){
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
              updatedCookie += "=" + optionValue;
            }
        }
        console.log("set cookie");
        document.cookie = updatedCookie;
    };

    /**
     * Возвращает значение 
     * @param {*} key 
     * @returns 
     */
    StorageService.prototype.get = function (key) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    };
    
    /**
     * Удаляет
     * @param {} key 
     */
    StorageService.prototype.remove = function (key){
        this.set(key,"",{
            "max-age":"-1"
        });
    };
    return new StorageService();
}();
  
export default Cookie;