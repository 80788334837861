import Cookie from '@/store/cookie';

import env from '@/enviroment';
export const rest = {
    /**
     * Вызывает метод апи
     * @param {String} method
     * @param {*} data
     * @param {*} request_method
     */
    call(method,data={},request_method="GET",with_session=true){
        if(method){
            var rest_data = Object.assign(
                with_session==true?{"session":Cookie.get('auth-token')}:{},
                data
            );
            var endpoint = env.backend+'/custom/service/v4_2/rest.php?method='+method+'&input_type=JSON&response_type=JSON&rest_data='+JSON.stringify(rest_data);
            return fetch(endpoint,{
                method:request_method
            }).then(response=>response.json());
        }
    },
};

export const randomString = (length=6)=>{
    var result=[];
    var characters='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength=characters.length;
    for(var i = 0; i < length; i++ ){
        result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
};

import lStorage from '@/store/localStorage.js';
export const ifAuthentificated = ()=>{
    var token = Cookie.get('auth-token');
    if(token){
        lStorage.set("auth-token",token);
        return rest.call("profile").then((response)=>{
            if(response && response.status && response.status == "ok" && response.id){
                return response;
            }
            return false;
        });
    }
    return false;
};
