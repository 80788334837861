import Vue from 'vue'
import Vuex from 'vuex'
import loading from './loading';
import Cookie from './cookie';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        loading,
        Cookie
    }
});

export default store;
