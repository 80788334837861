<template>
    <div class="popup-component">
        <transition name="opacity" v-if="show" appear>
            <div class="modal-overflow modal-wrapper" @click.self="close"></div>
        </transition>
        <transition name="bounce" v-if="show" appear>
            <div class="modal-wrapper modal-container">
                <div class="modal-popup-container">
                    <div class="close" @click="close">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-698c1b1e="" fill-rule="evenodd" clip-rule="evenodd" d="M12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858Z" fill="black" fill-opacity="0.65"></path></svg>
                    </div>
                    <div class="popup-container">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
    .popup-component{
        position: absolute;
        .modal-wrapper{
            position: fixed;
            top:0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }
        .modal-overflow{
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: rgba(234,237,242,.9);
            -webkit-backdrop-filter: saturate(180%) blur(8px);
            backdrop-filter: saturate(180%) blur(8px);
            z-index: 2000;
        }
        .modal-container{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            z-index: 2001;
            .modal-popup-container{
                margin-bottom: 8px;
                background: #fff;
                border-radius: 24px;
                width: 100%;
                max-width: 412px;
                min-height: 360px;
                max-height: 100vh;
                overflow: auto;
                position: relative;
                padding: 42px;
                z-index: 1;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
                &>.close{
                    position: absolute;
                    cursor: pointer;
                    top: 42px;
                    right: 42px;
                }
                &>.popup-container{
                    width: 100%;
                    -ms-flex-item-align: auto;
                    align-self: auto;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    margin-bottom: 8px;
                    h2{
                        font-family: FuturaPT;
                        letter-spacing: .1rem;
                        color: #0b1022;
                        text-align: center;
                        margin-bottom: 40px;
                        font-weight: bold;
                        margin-top: 40px;
                    }
                    .form-title{
                        display:none;
                    }
                }
            }
        }
        &.gray-box{
            .modal-wrapper.modal-container{
                .modal-popup-container{
                    background: #F0F3F8;
                }
            }
        }
    }
</style>

<script>
    import i18n from '@/i18n';
    export default {
        name: 'Popup',
        components: {},
        data: function () {
            return {
                
            }
        },
        props:{
            show: {
                type: Boolean,
                required: true,
                default:true
            },
        },
        methods:{
            close(){
                this.$emit('closeEvent', null);
            }
        },
        computed:{
            
        },
    }
</script>