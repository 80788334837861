import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueTelInput from 'vue-tel-input';

import App from './App.vue'
import router from './router'
import store from './store'
import enviroment from "./enviroment";
import i18n from "@/i18n";

import 'vue-tel-input/dist/vue-tel-input.css';

Vue.config.productionTip = false

Vue.use(VueTelInput, {
    defaultCountry: 'RO',
    customValidate: false,
    invalidMsg: 'Câmpul de telefon trebuie completat',
    autoFormat: false,
    dropdownOptions: {
        showDialCodeInSelection: false,
        showDialCodeInList: false,
        showSearchBox: true
    },
    inputOptions: {
        showDialCode: true,
        placeholder: 'Numărul de telefon',
        required: true,
        name: 'phone'
    }
});
Vue.use(VueMeta);

if (enviroment.domain) window.document.domain = enviroment.domain;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
