const loading = {
    namespaced: true,
    state: {
        is: 0,
    },
    mutations: {
        is: (state, is) => {
            if (is) {
                state.is++;
            } else {
                state.is--;
            }
        },
    },
    getters: {
        is: state => {
            return state.is !== 0
        },
    },
};
export default loading;