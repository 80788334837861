<template>
    <transition name="component-fade-route" mode="out-in">
        <router-view/>
    </transition>
</template>

<script>
    import enviroment from "@/enviroment.js";
    export default {
        name: 'App',
        metaInfo: {
            meta:(function(){
                var options = [];
                return options;
            }())
        },
        components: {}
    }
</script>

<style lang="scss">

    * {
        box-sizing: border-box;
    }

    body, div, dl, dt, dd, ul, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, input, textarea, p, blockquote, th, td {
        margin: 0;
        padding: 0;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    fieldset, img, abbr {
        border: 0;
    }

    address, caption, cite, code, dfn, em, strong, th, var {
        font-style: normal;
        font-weight: normal;
    }

    ul li {
        list-style: none;
    }

    caption, th {
        text-align: left;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 100%;
        font-weight: normal;
    }

    sup {
        vertical-align: text-top;
    }

    sub {
        vertical-align: text-bottom;
    }

    input, textarea, select {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }

    legend {
        color: #000;
    }

    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
        display: block;
    }

    @font-face {
        font-family: FuturaPT;
        src: url("assets/fonts/FuturaPT/FuturaPTMedium.otf") format("opentype");
    }

    @font-face {
        font-family: FuturaPT;
        font-weight: bold;
        src: url("assets/fonts/FuturaPT/FuturaPTBold.otf") format("opentype");
    }

    html, body {
        width: 100%;
        height: 100%;
        font-size: 0.695vw;
        margin: 0;
        background: #F2F2F2;
        overflow: overlay;
    }

    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }

    .component-fade-route-enter-active, .component-fade-route-leave-active {
        transition: opacity .2s ease;
    }

    .component-fade-route-enter, .component-fade-route-leave-to {
        opacity: 0;
    }
</style>