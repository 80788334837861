<template>
    <div class="simple-page">
        <div class="container">
            <section class="hero">
                <img src="@/assets/img/school.png" alt="#" class="school1">
                <h1>Despre Academie</h1>
                <p>Noi dezvoltăm interesul copiilor la studii,<img src="@/assets/img/books.png" alt="#" class="school2">  îi ajutăm să-și valorifice potențialul<img src="@/assets/img/flower.png" alt="#" class="school2"> cognitiv și intelectual și să-și găsească calea spre un viitor fericit și prosper!<img src="@/assets/img/success.png" alt="#" class="school2"></p>
                <div class="tab-buttons">
                    <input type="button" class="button" value="Istoria" onClick="document.getElementById('istoria').scrollIntoView();">
                    <input type="button" class="button" value="Valorile" onClick="document.getElementById('valorile').scrollIntoView();">
                    <input type="button" class="button" value="Echipa" onClick="document.getElementById('echipa').scrollIntoView();">
                    <input type="button" class="button" value="Contacte" onClick="document.getElementById('contacte').scrollIntoView();">
                </div>
            </section>
            <h2 id="istoria" style="max-width: 592px; margin: 0 auto;"><img src="@/assets/img/mortar.png" alt="#" class="school3">Academia Națională de Șah din Moldova - unul din cele mai mari Centre de predare a șahului online din Europa</h2>
            <div class="white-container person-list" style="max-width: 864px;">
                <div class="person">
                    <div class="year">2010</div>
                    <p >Am lansat Academia Națională de<br>Șah din Moldova -<span style="color: rgb(71, 70, 70);">Ion Gîrneț, co-fondator al Academiei,<br>coordonator de proiect</span></p>
                    <div class="person-pic">
                        <img src="@/assets/img/11.png" />
                    </div>
                </div>
                <div class="person">
                    <div class="year">2011</div>
                    <p>Am lansat procesul de antremamente<br>pentru șahul de performanță<br><span style="color: rgb(71, 70, 70);"> Viorel Iordachescu, co-fondator al Academiei,<br/> Mare Maestru Internațional, Antrenor FIDE</span></p>
                    <div class="person-pic">
                        <img src="@/assets/img/22.png" />
                    </div>
                </div>
                <div class="person">
                    <div class="year">2013</div>
                    <p>Am implementat proiectul ”Șahul Unește”,<br>finanțat de PNUD/UE,<br><span style="color: rgb(71, 70, 70);">Andy Shetter, consultant proiecte de la Corpul Păcii</span></p>
                    <div class="person-pic">
                        <img src="@/assets/img/44.png" />
                    </div>
                </div>
                <div class="person">
                    <div class="year">2014</div>
                    <p>Am elaborat și aprobat la Ministerul Educației<br>curricula ”Șahul” și alte documente normative -<br><span style="color: rgb(71, 70, 70);">Ion Botgros, coordonator științific, cercetător științific<br>în domeniul educaței cu vechime de peste 50 ani</span></p>
                    <div class="person-pic">
                        <img src="@/assets/img/55.png" />
                    </div>
                </div>
                 
                 <div class="person">
                    <div class="year year-hidden">0000</div>
                    <p>Am format și certificat primii 20 <br>pedagogi de șah din R. Moldova<br><span style="color: rgb(71, 70, 70);"> Sergiu Busuioc, consultant, doctor în<br> pedagogie, Maestru în sport al URSS</span></p>
                    <div class="person-pic content-pic">
                        <img src="@/assets/img/66.png" />
                    </div>
                </div>
                 <div class="person">
                    <div class="year year-hidden">0000</div>
                    <p>Am încheiat acord de colaborare și oferit Academiei <br>platforma interactivă de predare a șahului elaborată<br> de Fundația Kasparov -<br><span style="color: rgb(71, 70, 70);">Garry Kasparov, consultant,<br> al 13-lea Campion Mondial la șah</span> </p>
                    <div class="person-pic content-pic">
                        <img src="@/assets/img/77.png" />
                    </div>
                </div>
                <div class="person">
                    <div class="year">2015</div>
                    <p>Am lansat subdiviziunea jocului de șah<br>ca disciplină educațională<br><span style="color: rgb(71, 70, 70);">Maria Zinenco (Nazar), pedagog superior</span></p>
                    <div class="person-pic">
                        <img src="@/assets/img/88.png" />
                    </div>
                </div>
                <div class="person">
                    <div class="year">2018</div>
                    <p style="flex-wrap: wrap;">Am lansat procesul de predare a jocului<br>de șah în 7 instituții din mun. Chișinău -<br><span style="color: rgb(71, 70, 70);">Iurie Stingu, metodist superior</span</p>
                    <div class="person-pic">
                        <img src="@/assets/img/10.png" />
                    </div>
                </div>
                <div class="person">
                    <div class="year">2020</div>
                    <p style="flex-wrap: wrap;" >Am lansat proiectul de sah online<br><span style="color: rgb(71, 70, 70);">Corina Druță, metodist al orelor introductive</span></p>
                    <div class="person-pic">
                        <img src="@/assets/img/99.png" />
                    </div>
                </div>
            </div>
            <div id="education">
            <h2 style="margin-top:64px; max-width: 865px; margin: 0 auto;padding-top: 64px;" id="valorile">Credem că educația de calitate a jocului de șah va ajuta semnificativ copiilor noștri să-și făurească un viitor prosper</h2>
            <div class="benefits">
                <div class="cell">
                    <div class="image-wrapper">
                        <img src="@/assets/img/achievement.png" />
                    </div>
                    <div class="content-wrapper">
                        <div class="content-title">Rezultat</div>
                        <p style="max-width:356px;">Ne asumăm responsabilitatea pentru progres: împreună fixăm obiectivul și ajungem la el</p>
                    </div>
                </div>
                <div class="cell">
                    <div class="image-wrapper">
                        <img src="@/assets/img/pencils.png" />
                    </div>
                    <div class="content-wrapper">
                        <div class="content-title">Cunoştinţe</div>
                        <p style="max-width:289px;">Învățăm ceea ce este util nu numai pe tabla de șah, dar și în viața reală.</p>
                    </div>
                </div>
                <div class="cell">
                    <div class="image-wrapper">
                        <img src="@/assets/img/book.png" />
                    </div>
                    <div class="content-wrapper">
                        <div class="content-title">Relevanţă</div>
                        <p style="max-width:308px;">Urmărim trendurile și alegem ce e mai bun - în conținut, metode și tehnologii.</p>
                    </div>
                </div>
                <div class="cell">
                    <div class="image-wrapper">
                        <img src="@/assets/img/psychology.png" />
                    </div>
                    <div class="content-wrapper">
                        <div class="content-title">Diversitate în gîndire</div>
                        <p style="max-width:313px;">Vedem lumea diferit: apreciem simplitatea, toleranța și incluziunea</p>
                    </div>
                </div>
            </div>
            </div>
            <h2 style="max-width: 720px; margin: 0 auto; padding-top: 64px;" id="echipa">Echipa noastră. La fiecare curs lucrează peste 50 de persoane cu experiență în diferite domenii</h2>
            <div class="methods">
                <div class="make_method">
                    <div class="text-wrapper">
                        <h2 class="memories">Metodiști</h2>
                        <p class="designs">Elaborează curricule,<br> performează metodele, au<br> grijă să fie rezultate</p>
                    </div>
                    <div class="image-wrapper">
                        <img src="@/assets/img/teacher.png" alt="#" style="width:72px;">
                    </div>
                </div>
                <div class="make_method">
                    <div class="text-wrapper">
                        <h2 class="memories">Dezvoltatori</h2>
                        <p class="designs">Crează platformă interactivă <br>și aplicații mobile</p>
                    </div>
                    <div class="image-wrapper">
                        <img src="@/assets/img/flat.png" alt="#" style="width:50px;">
                    </div>
                </div>
                <div class="make_method">
                    <div class="text-wrapper">
                        <h2 class="memories">Scenariști și scriitori</h2>
                        <p class="designs">Ei crează eroi, scenarii,<br> jocuri și cântece</p>
                    </div>
                    <div class="image-wrapper">
                        <img src="@/assets/img/director.png" alt="#" style="width:72px">
                    </div>
                </div>
                <div class="make_method">
                    <div class="text-wrapper">
                        <h2 class="memories">Designeri</h2>
                        <p class="designs">Se gîndesc la utilizatori,<br>elaborează interfețe frumoase<br> și comode în utilizare</p>
                    </div>
                    <div class="image-wrapper">
                        <img src="@/assets/img/graphic.png" alt="#" style="width:72px">
                    </div>
                </div>
                <div class="make_method" >
                    <div class="text-wrapper">
                        <h2 class="memories">Pictori</h2>
                        <p class="designs">Pictează ilustrații pentru lecții,<br> elaborează benzi desenate și<br> desene animate</p>
                    </div>
                    <div class="image-wrapper">
                        <img src="@/assets/img/painter.png" alt="#" style="width:72px">
                    </div>
                </div>
                <div class="method_image">
                    <img src="@/assets/img/rating.png" alt="#" >
                </div>
            </div>
            <h4 style="margin-top: 112px;" id="contacte">Documente normative:</h4>
            <ul class="docs">
                <li>
                    <a href="docs\1.PDF" target="_blank" >Acord de colaborare între Ministerul Educației și Academia Națională de Șah din Moldova</a>
                </li>
                <li>
                    <a href="docs\2.PDF" target="_blank" >Acordul de colaborare între Ministerul Educației - Uniunea Europeană de Șah - Academia Națională de Șah din Moldova</a>
                </li>
                <li>
                    <a href="docs\3.PDF" target="_blank" >Acord de colaborare între Ministerul Educației - Fundația de Șah Kasparov - Academia Națională de Șah din Moldova </a>
                </li>
                <li>
                    <a href="docs\4.PDF" target="_blank" >Ordin privind aprobarea planului de învățămînt elaborat de Academie de către Ministerul Educației</a>
                </li>
                <li>
                    <a href="docs\5.PDF" target="_blank" >Aprobarea de către Ministerul Educației a Curriculei de predare a jocului de șah cu utilizarea tehnologiilor informaționale</a></li>
                <li>
                    <a href="docs\6.PDF" target="_blank" >Planul de învățămînt aprobat de Ministerul Educației</a>
                </li>
                <li>
                    <a href="docs\7.PDF" target="_blank" >Recomandare PNUD</a>
                </li>
                <li>
                    <a href="docs\8.PDF" target="_blank" >Recomandare fostul Campion Mondial G. Kasparov (în limba rusă) </a>
                </li>
                <li>
                    <a href="docs\9.PDF" target="_blank" >Autorizare instruire de către fostul ministru al Educației, Maia Sandu</a>
                </li>
            </ul>
            <div class="footer-form">
                <div class="col-8">
                    <div class="form-title">Încearcă gratuit și o să Vă fie clar</div>
                    <img class="group_img" src="@/assets/img/Group.png" alt="group">
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                    <RegistrationForm />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .person-list{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-gap: 40px;
        padding:48px 64px 70px;
        .person{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            position:relative;
            .year{
                font-family: FuturaPT;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 51px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #0B1022;
                float: left;
                justify-content: flex-end;
                &:after{
                    content:"";
                    display: block;
                    background-image: url('../../../assets/img/right_arrow.png');
                    background-repeat: no-repeat;
                    background-position: center center;
                    width:80px;
                    height:40px;
                }
                &.year-hidden{
                    opacity:0;
                    color:#fff;
                    &:after{
                        background-image:none;
                    }
                }
            }
            >p{
                font-family: FuturaPT;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 30px;
                align-items: center;
                color: #000000;
                display: flex;
                float:left;
                width:100%;
                flex:1;
                padding-left:30px;
                flex-wrap: wrap;
            }
            >.person-pic{
                float:left;
                width:92px;
                display: grid;
                grid-template-columns: repeat(1,1fr);
                grid-gap:30px;
            }
        }
        @media(max-width:768px){
            padding:15px;
            .person{
                flex-wrap: wrap;
                &>.year{
                    margin-top: 23px;
                }
                >p{
                    flex-wrap: wrap;
                    width: 100%;
                    padding: 40px 0px 0px;
                    width: 100%;
                    min-width: 100%;
                }
                .person-pic{
                    position: absolute;
                    top:0px;
                    right: 0px;
                    &.content-pic{
                        right:initial;
                        left:0px;
                    }
                }
            }
        }
    }
    .methods{
        max-width: 1120px;
        width: 100%;
        margin: auto;
        margin-top: 64px;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap:30px;
        .make_method,.method_image{
            width:100%;
            >img{
                max-width: 100%;
            }
        }
        .make_method{
            background: #FFFFFF;
            border: 1px solid #F3F5F9;
            box-sizing: border-box;
            box-shadow: 0px 22.3363px 17.869px rgba(0,0,0,0.04);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            justify-content: space-between;
            > p{
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                color: #0B1022;
            }
            .image-wrapper{
                padding-bottom: 40px;
                >img{
                    max-width: initial;
                    height: auto;
                }
            }
        }
        @media(max-width:768px){
            grid-template-columns: repeat(1,1fr);
            .make_method{
                flex-direction: column-reverse;
                padding:15px 0px;
                .text-wrapper{
                    .memories{
                        font-size:34px;
                        margin-bottom: 0px;
                        padding-bottom: 15px;
                    }
                     .designs{
            
                        font-size: 18px;
                        line-height: 23px;
                    }
                }
                .image-wrapper{
                    padding-bottom: 10px;
                }
            }
            .method_image{
                display:none;
            }
        }
        .designs{
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
        }
    }
    ul.docs{
        li{
            margin-bottom: 1rem;
            a{
                text-decoration: underline;
                color: #0b1022;
            }
        }
    }
    .footer-form{
        margin-top: 51px;
        display: flex;
        justify-content: space-between;
        .form-title{
            font-family: FuturaPT;
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            line-height: 51px;
            max-width: 1160px;
            width: 100%;
            margin: 0 auto;
            color: #000000;
            margin-top: 90px;
        }
        .form-step{
            max-width: 300px;
        }
        
        @media(max-width:768px){
            display: inline-block;
            .group_img{
                display:none;
            }
            .registration_form{
                .form-step{
                    .form-title{
                        margin:20px 0px;
                    }
                }
            }
        }
    }
</style>

<script>
    import i18n from '@/i18n';
    import RegistrationForm from '@/views/components/RegistrationForm/RegistrationForm.vue';

    export default {
        name: 'Aboutus',
        components: {
            RegistrationForm
        },
        data: function () {
            return {}
        },
        methods: {},
        computed:{
            url_prefix(){
                return (i18n.locale=="ro"?"":"/"+i18n.locale);
            }
        },
    }
</script>